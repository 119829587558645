import React from "react"
import { ThemeProvider } from "styled-components"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Navigation from "sections/Navigation"
import Footer from "sections/Footer-classic"

import Blog from '../../mySections/BlogContent/index'
import PrivacyContent from '../../mySections/PrivacyData/en/privacyE_data_basic'

import { GlobalStyle } from "sections/app.style"
import theme from "theme/classic/themeStyles"

const Privacy_Pomo_En = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <Layout>
      <SEO title="Privacy Policy" />
      <Navigation pageName="404" />
      <Blog
        title={"Privacy Policy"}
        content={PrivacyContent}
      />
      <Footer />
    </Layout>
  </ThemeProvider>
)
export default Privacy_Pomo_En